.bookmarks {
    width: 75%;
    margin-top: 50px;
    font-family: sans-serif;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
}

.col {
    width: 120px !important;
    padding: none !important;
}

.site {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    border: 1px solid transparent;
}
.site:hover {
    background-color: rgb(222, 226, 228);
    cursor: pointer;
}
.site p {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
}
.logo {
    font-size: 25px;
    width: 50px !important;
    height: 50px;
    margin-left:35px;
    margin-top: 20px;
    background-color: rgba(241, 243, 244, 1);
    text-align: center;
}

.round {
    border-radius: 50%;
}

.icon {
    margin-top: 12px;
}

.headerRow {
    height: 100px;
    vertical-align: bottom !important;
}

.headerText {
    font-size: large;
    font-weight: bold;
}

.loadingScreen {
    text-align: center;
    margin-top: 100px;
}

.loadingText {
    font-size: x-large;
}