.topbarbutton
{
    padding: 0px 10px;
    font-size: 14px;
    color: black;
    background-color: #f6f8fc;
    border: 0px;
}

.topbarbutton:hover
{
    color: black;
    background-color: #f6f8fc;
    border: 0px;
}

td.pagefooter
{
    background-color: #0f9ddd;
}

.halfopaque
{
    opacity: 0.70;
}

.mainmenuicon
{
    height: 16px;
}

.mainmenuhomeicon
{
    height: 22px;
}

.mainmenusubheader
{
    padding-bottom: 6px;
    padding-top: 6px;
    cursor: default;    
}

.mainhome
{
    font-size: 13px;
    font-weight: 400;  
}

.languageicon
{
    height: 20px;
    padding-bottom: 3px;
}

.footer {
    position:absolute;
    bottom:0;
    width:100%;
 }