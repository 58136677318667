hr {
  margin-top: 0;
  margin-bottom: 5px;
}

.configureButton {
  float: right;
  margin-top: -7px;
}

.subtext {
  margin-left: 10px;
}

.syncbutton {
  float: right;
  width: 150px;
}

.synctext {
  color: black;
}

.syncdiv {
  margin-bottom: 10px;
}

.deletebutton {
  margin-top: 100px;
}

.container {
  max-width: 750px;
}

.firstdiv {
  margin-top: 50px;
}

.btn-light {
  background-color: rgb(226, 226, 226);
}

.imageArea {
  position: relative;
  width: 350px;
}

.headerLocation {
  position: absolute;
  top: 15px;
  left: 80px;
  color: white;
  font-size: x-large;
}

.textfieldLocation {
  position: absolute;
  top: 51px;
  left: 83px;
  height: 81px;
  width: 265px;
  overflow: auto;
}

.textfieldContent {
  background-color: transparent;
  border: none;
  resize: none;
  outline: none;
  color: white;
  font-family: Roboto;
  width: 275px;
  height: 75px;
  display: inline;
}

.notificationBody {
  color: white;
}

.notificationBodyBlue {
  color: rgb(18, 168, 238);
}

.notificationBodyGreen {
  color: rgb(56, 199, 56);
}

.actionbutton {
  float: right;
}

.actiondiv {
  margin-bottom: 10px;
}

.commandlink {
  border: 1px white solid;
}

.commandlink:hover {
  border: 1px gray dotted;
  cursor: pointer
}

.commandlinkheader {
  font-size:large;
  color:rgb(00, 78, 215);
}

.commandlinksubheader {
  font-size:small;
  color: rgb(00, 78, 215);
}

.datasourceerror {
  font-size:small;
  color: red;
  font-weight: bold
}

.datasourceerrorinfo {
  font-size:small;
  color: green;
  font-weight: bold
}

table.datasourcefields {
  border-spacing: 0px 20px 0px 20px;
}