@font-face {
    font-family: 'Open Sans'; 
    src: local('Open Sans'), url('../Assets/Fonts/OpenSans-Regular.ttf')
}

@font-face {
    font-family: 'Roboto Condensed'; 
    src: local('Roboto Condensed'), url('../Assets/Fonts/RobotoCondensed-Regular.ttf')
}

body 
{
    background: var(--brand_background); 
    font: var(--brand_font);
    color: var(--brand_color);
    padding: 0;
    margin: 0;
}

select
{
    background: var(--brand_surface_background);
    border-radius: 16px;
    border: var(--brand_surface_border) !important;
    border-width: 2px !important;
    padding: 7px;
    color: var(--brand_surface_color);
    font: var(--brand_font);
}

input[type="text"],input[type="password"]
{
    border-radius: 16px;
    border: 2px #E7E5E5 solid;
    color: #271A1D;
    padding-top: 10px;
    padding-bottom: 8px;
}

input[type="text"]::placeholder 
{
    color: #ACA6A7;
}

input[type="radio"]
{
    border: 2px red solid;
}

input[type="file"].filebrandbuttonprimary::file-selector-button {
    border-radius: 16px;
    background: var(--brand_primarybutton_background) !important;
    border-color: var(--brand_primarybutton_background) !important;
    color: var(--brand_primarybutton_color) !important;
    user-select: none;  
    padding: 8px 16px;
    font: var(--brand_button_font); 
  }

input[type="file"].filebrandbuttonprimary::file-selector-button:hover:enabled
{
    background: var(--brand_primarybutton_backgroundhighlight) !important;
}

.brandbuttonkeeporiginalstyle
{
    user-select: none;  
    margin: 12px 2px;
    padding: 8px 16px;
    font: var(--brand_button_font); 
}

.brandbuttonoutline
{
    border-radius: 16px;
    background: var(--brand_outlinebutton_background) !important; 
    border: 2px #271A1D solid !important;
    color: var(--brand_outlinebutton_color) !important;
    user-select: none;  
    margin: 12px 2px;
    padding: 8px 16px;
    font: var(--brand_button_font); 
}

.brandbuttonoutline:hover:enabled
{
    background: var(--brand_outlinebutton_backgroundhighlight) !important; 
    color: var(--brand_outlinebutton_colorhighlight) !important; 
}

.brandbuttonprimary
{
    border-radius: 16px;
    background: var(--brand_primarybutton_background) !important;
    border-color: var(--brand_primarybutton_background) !important;
    color: var(--brand_primarybutton_color) !important;
    user-select: none;  
    margin: 12px 2px;
    padding: 8px 16px;
    font: var(--brand_button_font); 
    letter-spacing: 1px;
}

.brandbuttonprimary:hover:enabled
{
    background: var(--brand_primarybutton_backgroundhighlight) !important;
}

.brandbuttonscriptfailed
{
    background: #F4C5C5 !important; 
}

.brandbuttonscriptok
{
    background: #C5F4D0 !important; 
}

.brandbuttonsignout
{
    border-radius: 16px;
    background: var(--brand_maintitle_background);
    font: var(--brand_button_font) !important;
    color: var(--brand_color) !important;
    padding: 6px 16px 6px 16px;
    border: 2px var(--brand_color) solid !important; 
    font-size: 14px; 
    user-select: none;
}

.brandbuttonsignout:hover:enabled
{
    background: var(--brand_signoutbutton_backgroundhover) !important;
}

.brandbuttonsignoutkeeporiginalstyle
{
    font: var(--brand_button_font) !important;
    color: var(--brand_color) !important;
    padding: 6px 16px 6px 16px;
    border: 2px var(--brand_color) solid !important; 
    font-size: 14px; 
    user-select: none;
}

.copyrightbottom
{
    color: var(--brand_copyright_color); 
    font: var(--brand_copyright_font); 
}

.errortext
{
    color: var(--brand_color_error)
}

.graytext
{
    color: var(--brand_color_greyedout)
}

.linktext
{
    color: var(--brand_color_link);
    cursor: pointer;
}

.linktext:hover 
{
    color: var(--brand_color_linkhotlight);
    text-decoration: underline;
}

.mainconfigurebutton
{
    text-decoration: underline;
    color: var(--brand_namevalue_color) !important;   
}

.mainmenu
{
    background: var(--brand_mainmenu_background);  
    color: var(--brand_mainmenu_color);  
    font: var(--brand_mainmenu_font);
    border: var(--brand_surface_border);
    border-radius: 16px;
}

.mainmenuitem
{
    background: var(--brand_mainmenu_background);  
    color: var(--brand_mainmenu_color);  
    font: var(--brand_mainmenu_font);
    border-radius: 16px; 
    cursor: pointer;
    user-select: none;
}

.mainmenusubitemsurfacedividertop {
    border-top: var(--brand_mainmenusubitem_bordertop);
}

.modal 
{
    border-radius: 16px; 
}

.namevalue
{
    color: var(--brand_namevalue_color); 
    font: var(--brand_namevalue_font); 
}

.namevaluenamecolumn
{
    font-weight: bold;
}

.notificationpreview
{
    padding: 10px 6px;
    font: var(--brand_font);
    cursor: default; 
}

.notificationpreviewbody
{ 
    padding: 8px 16px; 
}

.notificationpreviewheader
{
    padding: 12px;
    font: var(--brand_title_font);
}

tr.pagetop
{
    background: var(--brand_maintitle_background);
    color: var(--brand_maintitle_color);
    font: var(--brand_maintitle_font);
}

.separatorbar
{
    height: var(--brand_separatorbar_height);
    width: var(--brand_separatorbar_width);
    background: var(--brand_separatorbar_background);
}

.surface
{
    background: var(--brand_surface_background);
    color: var(--brand_surface_color);
    border: var(--brand_surface_border);
    border-radius: 16px;
}

.surfacedisabled
{
    color: var(--brand_surface_disabled_color);
}

.surfacedividerbottom
{
    border-bottom: var(--brand_surface_border);
}

.surfaceimage
{
    background: var(--brand_surface_color);
}

.surfaceselected
{
    background: var(--brand_surface_selected_background);
    color: var(--brand_surface_selected_color);
}

.wizardapplicationitem
{
    font: var(--brand_wizardintegrationcard_font);
    padding: 6px;
    cursor: pointer;
}

.wizardapplicationitemadd
{
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.wizardapplicationitemcustom
{
    background: #0f9ddd;
    color: white;
}

.wizardapplicationshadow
{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .1) 
}

.wizardfieldname
{              
    font: var(--brand_wizardfieldname_font); 
    margin-bottom: -8px;
    margin-top: 8px;
}

.wizardfooter 
{
    width:100%;
    bottom: 25px;
    height: 50px;
    text-align: center;
    background-color: var(--brand_wizardfooter_background); 
}

.wizardheader
{
    background: var(--brand_maintitle_background);
}

.wizardpopupheader 
{
    background: var(--brand_wizardpopup_background) !important;
    font: var(--brand_wizardpopupheader_font); 
    cursor: default;
    padding: 4px 8px;
}

.wizardpopupitem 
{
    font: var(--brand_wizardpopup_font); 
    padding: 6px 8px;
    cursor: pointer;
}

.wizardpopupitem:hover 
{
    background: var(--brand_wizardpopup_hover_background);
}

.wizardstepheader 
{
    font: var(--brand_title_font);
}

.wizardstepsubheader 
{
    font: var(--brand_subtitle_font);
}

.wizardsurface
{
    border-collapse: separate;
    background: var(--brand_wizardsurface_background);  
}