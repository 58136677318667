.radiogroup {
  --_clr-primary: var(--brand_radio_primarycolor);
  --_clr-hover: var(--brand_radio_hovercolor);
  --_clr-checked: var(--brand_radio_checkedcolor);
  --_clr-current: var(--_clr-primary);
}

.radiogroup input[type="radio"] {
  appearance: none;
  outline: none;
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.25rem;
  background: transparent;
  border: 2px solid var(--_clr-current);
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
  position: relative;
}

.radiogroup input[type="radio"]::after {
  content: "";
  position: absolute;
  inset: 0.25rem;
  opacity: 0;
  scale: 0;
  transition:
    opacity 150ms ease-in-out,
    scale 150ms ease-in-out;
  background-color: var(--_clr-checked);
  border-radius: inherit;
}

.radiogroup input[type="radio"]:focus-visible,
.radiogroup input[type="radio"]:focus-visible + label,
.radiogroup input[type="radio"]:hover,
.radiogroup input[type="radio"]:hover + label {
  --_clr-current: var(--_clr-hover);
}
.radiogroup input[type="radio"]:focus-visible::after,
.radiogroup input[type="radio"]:hover::after {
  opacity: 0.5;
  scale: 1;
  background-color: var(--_clr-hover);
}

.radiogroup input[type="radio"]:checked + label:not(:hover),
.radiogroup input[type="radio"]:checked:not(:hover) {
  --_clr-current: var(--_clr-checked);
}
.radiogroup input[type="radio"]:checked::after {
  opacity: 1;
  scale: 1;
}