.redtext
{
    color: red;
}

td.cloudctiicon
{
    padding-left: 30px
}

img.cloudctiicon
{
    height: 80px;
}

td.integrations
{
    vertical-align: bottom;
    font-weight: 900;
    font-size: 26px;
}

.wizardheadertable
{
    font-weight: bolder;
}

.wizardheaderlight
{
    background-color: #d5e6fb;
    font-weight: bolder;
    color: black;
    vertical-align: middle;
}

td.wizardheaderdark
{
    background-color: #0f9ddd;
    font-weight: bolder;
    color: white;
    vertical-align: middle;
    align-items: center;
}

.wizardheaderdisabled
{
    opacity: 0.15; 
}

img.stepimage 
{
    height: 40px;  
}

img.stepimageselected 
{
    height: 9px;  
    display: block;
    margin-top: -1px
}

input.wizardsearch
{
    font-size: 16px;
    color: gray;
    padding: 10px 14px;
    margin: 8px 0;
    border: 1px gray solid;
    box-sizing: border-box;
}

td.wizardsearch
{
    vertical-align: middle;  
}

.wizardsearchbutton
{
    height: 48px;  
    vertical-align: middle;  
    padding-bottom: 2px;
}

.wizardaddcustombutton
{
    height: 41px;  
}   

table.wizardapplicationitemblue
{
    padding: 14px;
    border-collapse:separate;
    border:solid silver 1px;
    border-radius:14px;
    background: #0f9ddd;
    color: white;
    font-size: 16px;
    font-weight: 900;
    box-shadow: 4px 8px 12px #bbbbbb;
    cursor: pointer
}

.wizardapplicationitemblueadd
{
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.wizardapplicationitemblueadd:hover
{
    color: black;  
    background-color: white;
    border-color: white;
}

.wizardbackbutton
{
    border:solid #d5e6fb 1px;
    color: white;
    font-size: 12px;
    padding: 12px;
    font-weight: bold;
}

img.wizardbuttonicon
{
    height: 16px
}

.wizardnextbutton
{
    color: white;
    font-size: 12px;
    padding: 12px;
    font-weight: bold;
}

td.wizardoutercell
{
    background-color: #ecf4fd;
    border-collapse:separate;
    border-radius:20px;
}

.wizardinputfield
{
    padding: 10px 14px;
    margin: 8px 0;
    width: 100%;
}

.wizardinputfielddisabled
{
    background: #dddddd;
    padding: 10px 14px;
    margin: 8px 0;
    border: 1px gray solid;
    width: 100%;
}

input.wizardinputfieldcountrycode
{
    padding: 10px 19px;
    margin: 8px 0;
}

span.wizardcountrycodeplus
{
    position: relative;
    left: -144px;    
}

.wizardstepnotificationsectionheader
{
    font-weight: 900;
    font-size: 26px;   
}

.wizardstepnotificationsectionheadersmall
{
    color: silver;
    font-weight: 900;
    font-size: 26px;   
}

tr.smallrow
{
    line-height: 0px;
}

img.wizardscriptitemremove
{
    height: 19px;  
    padding-bottom: 2px;
}

img.wizardscriptitemdelete
{
    height: 40px;  
    cursor: pointer;
}

.wizardscriptsubheader
{
    font-size: 16px; 
    padding: 8px 0px; 
}

.wizardreviewname
{
    background-color: #ecf4fd;
    border-bottom: 1px #d5e6fb solid;
    border-top: 1px #d5e6fb solid;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: bold;
}

.wizardreviewvalue
{
    background-color: white;
    border-bottom: 1px #d5e6fb solid;
    border-top: 1px #d5e6fb solid;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: bold;
}

.wizardcommandlink
{
    background-color: #ecf4fd;
    border-collapse:separate;
    border-radius:20px;
    cursor: pointer;    
}

.wizardcommandlinkheader
{
    font-size: 16px;
    font-weight: bold;
}

.wizardcommandlinksubheader
{
    font-size: 12px;
}

input.testscriptinput 
{
    width: 100%
}

.normalcursor 
{
    cursor: default
}